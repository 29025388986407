import {API} from "aws-amplify";

export async function fetchClientIds(poolid, partner) {
    try {
        console.log(`Fetching client id path: /pool/${poolid}/${partner}`)
        const response = await API.get('authregister', `/pool/${poolid}/${partner}`);
        return response.clientId;
    } catch (error) {
        console.error('Error fetching client ID:', error);
        return 'defaultClientId'; // Use a default client ID in case of an error
    }
}