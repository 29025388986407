import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto"
import iwyzeImage from './resources/img/iwyze1.png';
import iwyzetheme from './resources/theme/iwyze.json'
import { ThemeProvider } from '@aws-amplify/ui-react';

const queryParams = new URLSearchParams(window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));

if (!queryParams.get('app') || queryParams.get('app') === '') {
root.render(<div>
        The api registration portal won't work without a partner app code.  Please use the following url format:
        <code>{window.location.origin + window.location.pathname}?app=<strong>Provided_App_Code_Goes_Here</strong></code>
    </div>)
}
else {
    root.render(
        <ThemeProvider theme={iwyzetheme}>
            <div style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={iwyzeImage} alt="iwyze" />
                    <label style={{ position: 'absolute', bottom: 0, right: 0 }}>
                        api integration
                    </label>
                </div>
            </div>
            <App />
        </ThemeProvider>
    );
}

reportWebVitals();
