import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import { fetchClientIds } from "./apiUtils";

Amplify.configure(awsconfig);



export default function AuthenticatorWithEmail() {
    const queryParams = new URLSearchParams(window.location.search);
    const partnerQueryParam = queryParams.get('app');
    const [clientId, setClientId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = await fetchClientIds(process.env.REACT_APP_USERPOOL_ID, partnerQueryParam);
                setClientId(id);

                awsconfig.aws_user_pools_web_client_id = id;
                Amplify.configure(awsconfig); // Ensure that Amplify uses the updated configuration
            } catch (error) {
                console.error('Error fetching client ID:', error);
                setClientId('defaultClientId');
            } finally {
                setIsLoading(false); // Set loading state to false when data is fetched
            }
        };

        fetchData().then();
    }, [setClientId, partnerQueryParam]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const services = {
        async handleSignUp(formData) {
            // Capture relevant information before the try block
            let { username, password, attributes } = formData;
            username = username.toLowerCase();
            attributes.email = attributes.email.toLowerCase();
            attributes['custom:clientId'] = clientId;

            try {
                return await Auth.signUp({
                    username,
                    password,
                    attributes,
                    autoSignIn: {
                        enabled: true,
                    },
                });
            } catch (error) {
                console.error('Error during SignUp:', error);

                // Log relevant information
                console.log('Failed SignUp Info:', {
                    username,
                    password,  // You may want to avoid logging passwords for security reasons
                    attributes,
                    clientId,
                });

                // Rethrow the error to propagate it to the calling code
                throw error;
            }
        },
    };



    return (
        <Authenticator services={services} initialState="signIn">
            {({ signOut, user }) => (
                <main style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '600px',
                    padding: '20px',
                    boxSizing: 'border-box',
                    textAlign: 'left',
                }}>
                    <Typography variant="h5">Welcome user: {user?.attributes.email}</Typography>
                    <br />
                    <br />
                    <Typography variant="body1">You have registered to integrate with the iWyze API's.</Typography>
                    <br />
                    <Typography variant="body1">Your company and company code: <strong>{partnerQueryParam}</strong></Typography>
                    <Typography variant="body1">Your company's client id: <strong>{clientId}</strong></Typography>
                    <Typography variant="body1">
                        The client id is used for obtaining authentication tokens. Please keep it safe.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body1">
                        API Documentation: <Link href="https://docs.api.iwyze.io/">docs.api.iwyze.io</Link>
                    </Typography>
                    <br />
                    <br />
                    <Button variant="contained" onClick={signOut} style={{ backgroundColor: '#349c26', width: '20%' }}>
                        Sign out
                    </Button>
                </main>
            )}
        </Authenticator>

    );
}
